import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Features from '@solid-ui-blocks/Features/Block06';
import Clients from '@solid-ui-blocks/FeaturesWithPhoto/Block05';
import Companies from '@solid-ui-blocks/Companies/Block01';
import CalcularRoiForm from '@solid-ui-blocks/CalcularRoiForm/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import styles from './_styles';
import { Helmet } from 'react-helmet';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01';

const CalcularRoi = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    return (
        <Layout {...props}>
            <Helmet>
                <title>Calcule o Retorno Sobre Investimento do JUIT Rimor para seu escritório</title>
                <meta name="title" content="Calcule o Retorno Sobre Investimento do JUIT Rimor para seu escritório" />
                <meta
                    name="description"
                    content="Contratar o JUIT Rimor é certeza de economia, de tempo e de dinheiro. Saiba aqui quanto você pode economizar ao contratar nossa solução."
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://juit.io/calcular-roi" />
                <meta property="og:title" content="Calcule o Retorno Sobre Investimento do JUIT Rimor para seu escritório" />
                <meta
                    property="og:description"
                    content="Contratar o JUIT Rimor é certeza de economia, de tempo e de dinheiro. Saiba aqui quanto você pode economizar ao contratar nossa solução."
                />
                <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://juit.io/calcular-roi" />
                <meta property="twitter:title" content="Calcule o Retorno Sobre Investimento do JUIT Rimor para seu escritório" />
                <meta
                    property="twitter:description"
                    content="Contratar o JUIT Rimor é certeza de economia, de tempo e de dinheiro. Saiba aqui quanto você pode economizar ao contratar nossa solução."
                />
                <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />
                <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
            </Helmet>
            {/* Modals */}
            <ContactModal />
            {/* Blocks */}
            <Header content={content['header-light']} permanentBlack menuJustify="space-around" />
            <Container variant="full" sx={styles.heroContainer}>
                <Features content={content['features']} />
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Clients content={content['clients']} />
            <Divider space="4" />
            <Companies content={content['companies']} />
            <Divider space="4" />
            <CalcularRoiForm />
            <Divider space="5" />
            <FeatureOne content={content['feature-one']} />
            <Divider space="5" />

            <Footer content={content['footer']} />
        </Layout>
    );
};

export const query = graphql`
    query innerpageCalcularRoiBlockContent {
        allBlockContent(filter: { page: { in: ["site/calcular-roi", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;

export default CalcularRoi;
