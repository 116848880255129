import React from 'react';
import { Flex, Box } from 'theme-ui';
import ContentText from '@solid-ui-components/ContentText';
import Icon from '@solid-ui-components/ContentIcon';

const ListItem = ({
    icon,
    iconProps,
    text,
    description,
    compact,
    vertical,
    center,
    middle, //Centering for horizontal layout
    flexStart,
    paddingLeft,
}) => (
    <Flex
        sx={{
            paddingLeft: paddingLeft || 0,
            flexDirection: vertical ? 'column' : 'row',
            alignItems: center ? `center` : `flex-start`,
            textAlign: center && vertical ? `center` : `unset`,
            justifyContent: middle ? `center` : flexStart ? `flex-start` : `unset`,
            '& + &': { mt: description ? (compact ? 3 : 4) : 2 },
        }}>
        {icon?.src && (
            <Box
                sx={{
                    display: `inline-flex`,
                    flexShrink: 0,
                    [vertical ? 'mb' : 'mr']: compact ? 2 : 3,
                }}>
                <Icon content={icon} size="sm" p={compact ? 1 : undefined} {...iconProps} />
            </Box>
        )}
        <Box sx={{ alignSelf: vertical ? `auto` : `center` }}>
            <ContentText content={text} />
        </Box>
    </Flex>
);

ListItem.defaultProps = {
    iconProps: {},
};

export default ListItem;
