/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    position: `relative`,
    pt: [6, 7],
    '::before': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `90%`,
      top: 0,
      right: 0,
      zIndex: -1,
      background: t => `linear-gradient(
        ${t.colors.alpha} 0%,
        transparent 100%
      )`,
    }
  }
}
